.inputFile img {
  display: block;
  max-width: 100%;
  margin-bottom: 0.5rem;
}

.MuiButton-textPrimary {
  color: #007bff !important;
}

.MuiPickersToolbar-toolbar {
  background-color: #007bff !important;
}

.MuiPickersDay-daySelected {
  background-color: #007bff !important;
}

.MuiTypography-colorPrimary {
  color: #007bff !important;
}

.MuiInput-underline:after {
  border-bottom: 2px solid #007bff !important;
}

.form-group.required > label:after,
.form-row.required label:after,
.required label:after {
  content: ' *';
  color: red;
  font-weight: bold;
}

.clear-button {
  cursor: pointer !important;
  text-decoration: none !important;
}

.error-boundary-container {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column wrap;
  height: 100%;
}

.error-boundary-container .title {
  font-weight: 900;
  color: #bbb;
  font-size: 72px;
  margin-bottom: 12px;
}

.error-boundary-container .subtitle {
  font-weight: 600;
  color: #333;
  margin-bottom: 3px;
  font-size: 20px;
}
.error-boundary-container .desc {
  font-weight: 400;
  color: #999;
  max-width: 340px;
  margin: 0 auto;
}

.error-boundary-container .btn {
  border-radius: 25px;
  background-color: #bbb;
  padding: 10px 25px;
  color: white;
  font-weight: 900;
  margin-top: 58px;
}

.error-boundary-container .btn:hover {
  background-color: #aaa;
  color: white;
}

.error-boundary-container .text-muted {
  color: #eee;
  font-size: 14px;
  margin-bottom: 10px;
}

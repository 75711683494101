.FormTemplate_unicolumn {
    width: 500px !important;
    margin: 0 auto;
    padding: 0 1rem;
}
.FormTemplate_responsive .FormTemplate_unicolumn {
    width: auto !important;
    max-width: 500px !important;
}
.FormTemplate_unicolumn h2 {
    text-align: center;
}
.FormTemplate_bicolumn {
    width: 1000px !important;
    margin: 0 auto;
}
.FormTemplate_bicolumn:after {
    content: '';
    display: block;
    clear: both;
}
.FormTemplate_bicolumn > div {
    width: 500px;
    padding: 0 1rem;
    float: left;
}
.FormTemplate_bicolumn > div.full {
    width: 1000px;
}

.FormTemplate_title {
    text-align: center;
}

.FormTemplate_actions {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 1rem 0;
    background-color: white;
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2);
}

.FormTemplate_actions > .row {
    /* max-width: 500px !important; */
    margin: 0 auto;
}

.FormTemplate_footer {
    height: 150px;
}

.FormTemplate_group_button {
    padding: 10px 0px 20px 0px;
}

.Center_div {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Pageof {
    font-size: 13px;
}

.Center_vertical_div {
    height: 100%;
    display: flex;
    align-items: center;
}

.select-error div {
    border-color: #dc3545 !important;
}

.date-error {
    border-color: #dc3545 !important;
}

.input-error {
    border-color: #dc3545 !important;
}
.Home--hidden {
    display: none;
}

.Home_nodata {
    text-align: center;
    font-style: italic;
}

.Home_version {
    color: #777;
}

.Home_tabs {
    overflow-x: auto;
    white-space: nowrap;
}
.Home .nav.nav-tabs {
    display: block;
    background-color: #f8f9fa;
    padding: 0 1rem;
}
.Home .nav-tabs .nav-item {
    display: inline-block;
}

.Home .ReactTable .-padRow {
    min-height: 88px;
}

.Home_buttons,
.Nomination_button {
    text-align: right;
}
